import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col ,Progress  } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class AmoPage extends React.Component {
  render() {

    const data = this.props.data
        const pic06 = data.pic06.childImageSharp.fluid
        const pagebgamo = data.pagebgamo.childImageSharp.fluid


return (
  <Layout pageTitle="Arama Motoru Optimizasyonu">
  <SEO title="Arama Motoru Optimizasyonu - Seo - Amo" keywords={[`istanbul Web Tasarım`, `Arama Motoru Optimizasyonu`, `Seo`]} description="İstanbul Web Tasarım farkı ile potansiyel müşterilerinize rakiplerinize nazaran kalıcı bir üstünlükle erişin" />

    <section className="bginpage">
    <Img fluid={pagebgamo}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Arama Motoru Optimizasyonu"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Arama Motoru Optimizasyonu
             </h1>
             <p>  Kurumsal ve E-Ticaret Tabanlı Projeler için kalıcı üstünlükler sağlamayı hedefleyen arama motoru optimizasyonu çalışmamızla tanışmaya davetlisiniz...</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md={{ size: 10, offset: 1 }}  className="text-center pdbot30">
      <h1 className="font-size-normal  section-title">
               <small>Google Seo,Yandex Seo,Bing Seo ...</small>
 Arama Motoru Optimizasyonu
                <div className="bar"></div>
           </h1>
           <p>“Search Engine Optimization” kısaltması olarak kullanılan seo türkçeye uyarlanmış haliyle “Arama motoru optimizasyonu” anlamına gelmektedir.Arama motoru optimizasyonu kişilerin ve kurumların kullandığı web sitelerinin Google, Yandex, Bing, Yahoo ve diğer arama motorlarında çeşitli anahtar sözcüklerinizde en iyi sıralamalarda çıkmasını sağlamak ve doğrudan doğruya potansiyel müşterilerinize ulaşmayı hedeflemek için uygulanır</p>
           </Col>
           </Row>
<div className="boxer boxerpad">
<Row>
  <Col md="12" className="text-center">
    <h3>Neden Arama Motoru Optimizasyonu Yapılmalı ?</h3>
  </Col>
</Row>
<Row>
  <Col md="6">

     <div>1.Sıradaki Site (62%)</div>
     <Progress striped color="success" value={62} />
     <div>2.Sıradaki Site (21%)</div>
     <Progress striped color="info" value={21} />
     <div>3.Sıradaki Site (5%)</div>
     <Progress striped color="warning" value={5} />
     <div>4.Sırada ve Altındaki Sıralamalar (1-12%)</div>
     <Progress striped color="danger" value="1" />
  </Col>
  <Col md="6">
    <p>İsterseniz duruma şu şekilde bakalım google optimizasyonu üzerine yapılan bir analiz çalışmasında rekabet hususunda yer alan 4 firmaya göz gezdirelim.Yapılan analiz boyunca kendi anahtar kelimelerinde sıralanan bu 4 firmanın istatiki değerleri ve müşteri portföylerine baktığımızda arama motoru optimizasyonun ne kadar değerli olduğunu görmekteyiz.Şöyle ki belirli bir anahtar kelimede yapılan çalışmada sıralanan 4 web sitesine internet kullanıcılarının giriş işlemleri şu şekilde olmaktadır. 1.sırada yer alan siteye kullanıcıların %62 si girerken ikinci sırada yer alan siteye %21 lik bir dilim girmekte ve 3.sırada yer alan siteye ise yalnızca %5lik bir dilim girmekte, ilk sıralarda bile gözlemlenen bu durum sizce bir firmanın büyümesinin gelişmesinin tamamiyle rastlantı olduğuna mı işarettir yoksa yapılan tüm çalışmaların kuruşu kuruşuna sizlere geri dönmesine mi?</p>
  </Col>
</Row>
</div>

  <Row>
    <Col md="12" className="text-center mb20 mt20">
      <h3>Arama Motoru Optimizasyonu Çalışmasını Nasıl Yapıyorsunuz ?</h3>
    </Col>
  </Row>

           <Row>
            <Col md="8">
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">  Sektörel Analiz;</h3>
              <p>Markanız için geliştireceğimiz Dijital Pazarlama stratejilerinde Hedef Kitlenizi ve hedef kitlenizin isteklerini en iyi şekilde belirlerleyerek Sektörel Rekabet Analizleri yöntemlerini (SWOT vb…) uyguluyoruz ve eksiklerinizi artılarınızı fırsat ve tehditleri göz önüne alarak popüler olmanız için iş fikirleri hazılıyoruz…</p>
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Stratejik planlama ve iş modeli oluşturma;</h3>
              <p>İhtiyaç duyulan analizler neticesinde belirli bir plan ile kolları sıvıyoruz.İlk olarak site içi optimizasyon kriterlerimizi yerine getiriyor daha sonrasında ise sektörel değişim göz önüne alınarak sizleri popüler yapmanın yöntemlerini ortaya kouyuyoruz.</p>
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Trend Analizi ve Değişim;</h3>
              <p>Trend analizi firma içi başarı olarak nitelendirebilirsiniz yıl sonu ve ortaları için belirli satış rakamları, belirli hedeflerimiz bulunmaktadır.Bu hedeflere ulaşılabilip ulaşılamadığı ortaya konulmakta ,ulaşılamadıysa neden ulaşılamadığı ,ulaşıldıysa bir sonraki yıl için hedefimiz ortaya konulmaktadır.</p>
            </div>
            </Col>
            </Row>
            </Col>
            <Col md="4">
             <Img fluid={pic06} alt="Mobil Web Tasarım" className="mt-50" />
            </Col>
           </Row>

           <Row>
           <Col md="12" className="text-center mb20">
           <h2>Arama Motoru Optimizasyonu Çalışmasının Nasıl Yapılır ? İçeriği Nedir ?</h2>
           </Col>
           </Row>
           <Row>
            <Col md="6">
              <h3 className="text-right mb20">Site İçi Optimizasyon</h3>
            <div className="single-features right-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Temiz Html 5 Css 3 Kodlama</h3>
              <p>Kodlamanın Temel kuralları baz alınarak doğru parsellenmiş olan siteler gerek hızlı açılmaları gerekse gereksiz kod barındırmamamları ile arama motorlarının gözdesi haline gelmiştir</p>
            </div>
            <div className="single-features right-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Şematik Belirteçler</h3>
              <p>Html 5 ın kuralları bir kenara dursun özellikle google algoritmaları keywords(anahtar sözcük) gibi belirteçlere bakmadığını defalarca belirtildi, bunun yerine sematik olarak belirtilen parsellerde ne amaçlı kullandığınıza bakmaktadır..</p>
            </div>

            <div className="single-features right-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Kullanıcı Deneyımı</h3>
              <p>Arama motorlarının en temel özelliği sitenin kullanıcıya sağlayacağı bilgi erişilebilirlik ve kullanım kolaylığı bu noktada mobil,tablet ve masaüstü uygulamalarıyla doğru şekilde iletilicek olan içerik kullanıcı deneyımını arttırıcak ve ziyaretçilerin sitenizi popüler olması yolunda adımlar atmasını sağlayacaktır</p>
            </div>

            <div className="single-features right-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">İçerik Optimizasyonu</h3>
              <p>Sitenizin içeriğinde bir makale yayınlamak isteyebilirsiniz fakat makalenizin doğru algoritmalarca oluşturulması ve kelime yoğunluğunun en iyi şekilde ayarlanması gerekir.Örneğin bir makalede altı çizili ,italik ve kalın belirteçler ile ana anahtar sözcüğünüzün kombinasyonlarına yer verilmelidir Google algoritmasının 120 den fazla kriter bulunmaktadır.</p>
            </div>
            <div className="single-features right-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Kombinasyonlar ve Sıkıştırmalar</h3>
              <p>Site açılış hızı ve doğru kombinasyonla çalışması vazgeçilmez bir özelliktir Özel olarak sıkıştırılmış siteler normal sitelerden 7 kat daha hızlı açıldığını biliyormuydunuz?</p>
            </div>

            </Col>
            <Col md="6">
            <h3 className="text-left mb20">Sitedışı Optimizasyon</h3>
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Backlink</h3>
              <p>Sitenizin diğer sitelerce popüler olarak gösterilmesi işlemi olarak nitelendirilebilir.Fakat backlink sağlamanında belirli kuralları vardır orneğin ne periyotlarla ve hangi sitelerden backlink alındığı önemlidir</p>
            </div>
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Sosyal Medya</h3>
              <p>Sosyal Medya günümüzde markalaşmanın ve kalıcı tanıtımın en doğru ve en hızlı yontemı olarak karsımıza cıkmaya devam ediyor.Bu noktada arama motorları kişilerin davranışlarını organik olarak gözlemlemek istemesi oldukça doğal bir harekettir.</p>
            </div>
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Optimize Edilmiş Reklamlar</h3>
              <p>Adwords vb uygulamalarda sitenizin içeriği ve anahtar sözcükleriniz google tekrar lanse edilmektedir.Şu şekilde düşünebilirsiniz belirli bir ücret karşılığında oto yedek parça sözcüğünde reklam satın alıyorsunuz sizce bu saatten sonra arama motorları tutupta sizin gprs saatığınızı düşünür mü yoksa jant kaporta sattığınızı mı düşünür?</p>
            </div>
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Rakip Analizi</h3>
              <p>Rakiplerinizin belirli bir popülerliği(hit) mevcut olabilir bu popülerliğin kaynağını keşfedebilir ve bu kaynağı hatta varsa daha iyi kaynakları kendiniz için kullanabilirsiniz.Şı şekilde düşünebilirsiniz sitenize verdiğiniz backlink ile rakip firmanın sitesine sağladığı backlink arasında ne tür bir fark var gelen ziyaretçi davranışı arasındaki fark nedir.</p>
            </div>
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Dizinler</h3>
              <p>Dizin siteleri sektörel rehber niteliği taşımaktadır bu sitelerin fazlasıyla farkında olan google,yandex ve bing gibi arama motorları dizinlerde katagorisel yapılandırmaları ele alarak sitenizin en doğru anahtar sözcüklerde yer alması yönünde yeniden yapılandırılmıştır.</p>
            </div>

            </Col>
           </Row>
           <Row>

           </Row>


    </Container>
    </section>

    <section className="section minemacera bg-f5 br-top-de pdtop30 pdbot30">
      <Container>
        <Row>
          <Col md="12" className="text-center">
            <h3>Kalıcı Kaliteli Arama Motoru Optimizasyonu</h3>
            <p>Bu liste uzadıkça uzar her konu başlığı kendi içerisinde çeşitli kriterlere göre ayrılmaktadır saymaya kalksak belki 300 den fazla farklı kriter sayabiliriz.Bu kriterlerin adının tamamına sanal pazarlama denilmektedir ve bu pazarlama esnasında yasadışı yollarla geliştirilmiş siteler ise 1 2 ay içinde sıralamalarından silinmektedir..Bu noktada önemli olan ise yalnızca ve yalnızca sizin doğru bir çalışma ile en iyi sıralamalarda yer almanızdır.Çünkü müşteri potansiyeli yalnızca en iyi sıralamalarda mevcuttur.Sizlerde ayrıntılı bilgi almak ve sitenize özel bir optimizasyon yaptırmak istiyorsanız bizimle iletişime geçmeniz en doğru karar olacaktır.Unutmayın siteiçi optimizasyon olmadan sitedışı optımızasyonunuz guclu olsada bir şey ifade etmeyecek sıralamalarınız hep geçiçi olacaktır.Bu noktada yetkinliği tam kendini kanıtlamış sıralamaları iyi olan bir web tasarım firması ve ekibi seçmeniz sizlere artı sağlayacaktır.</p>
              </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

}
}
export const pageQuery = graphql`
query {
pic06: file(
 relativePath: { regex: "/mobile-two.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pagebgamo: file(
 relativePath: { regex: "/bg13.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
